const CreatedBy = () => {
  return (
    <a
      className="fixed bottom-0 right-0 bg-white py-2 px-4 z-10 cursor-pointer rounded-tl-xl border-t border-l border-base-content/20  border-dashed text-sm font-semibold hover:bg-gray-100 duration-200 group"
      href="https://singlepage.ee"
      target="_blank"
    >
      <div className="flex flex-row justify-center items-center text-center gap-1.5 ">
        <div>
          ⚡️<span className="link link-accent">singlepage</span>
        </div>
      </div>
    </a>
  );
};

export default CreatedBy;
