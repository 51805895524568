import { useState } from "react";
import InfoModal from "./InfoModal";
import viberLogo from "./viber.svg";
import illustration from "./illustration.svg";
import axios from "axios";
import { BrandWhatsapp, Language } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import CreatedBy from "./createdBy";
import { saEvent } from "./sa_events";
function App() {
  const { t, i18n } = useTranslation();
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [fName, setFName] = useState("");
  const [lname, setLname] = useState("");
  const [industry, setIndustry] = useState("");
  const [email, setEmail] = useState("");
  const [docAmount, setDocAmount] = useState("");
  const [emplAmount, setEmplAmount] = useState("");
  const [message, setMessage] = useState("");
  const [formError, setFormError] = useState(null);
  const [success, setSuccess] = useState(null);
  const handleMessage = async () => {
    if (fName === "" || lname === "" || email === "")
    return setFormError("Nimi ja email on kohustulikud väljad!");
    try {
      saEvent('Form_Submitted')
      const apiURL = `https://offrain-marketing.vercel.app/api/email/fintax?fName=${fName}&lname=${lname}&industry=${industry}&email=${email}&docAmount=${docAmount}&emplAmount=${emplAmount}&message=${message}`;
      setFormError(null);
      setSuccess(null);
      const test = await fetch(`${apiURL}`);
      const testres = await test.json()
      console.log('testres :', testres);
      setSuccess("Kiri edukalt saadetud!");
      setFName("");
      setIndustry("");
      setLname("");
      setEmail("");
      setDocAmount("");
      setEmplAmount("");
      setMessage("");
    } catch (error) {
      console.log(error);
      setFormError("Midagi läks valesti proovi natukese aja pärast uuesti");
    }
  };
  return (
    <div className="">
      <InfoModal
        title={t("service1Title1")}
        onClose={(value) => setModal1(value)}
        open={modal1}
      >
        <div className="box-border mt-0 mb-8 md:mb-0 text-slate-600">
          {t("service1Content1")}
          <span>
            <ul className="mb-4 ml-4">
              <li>{t("service1Bullet1")}</li>
              <li>{t("service1Bullet2")}</li>
              <li>{t("service1Bullet3")}​​</li>
              <li>{t("service1Bullet4")}</li>
              <li>{t("service1Bullet5")} </li>
              <li>{t("service1Bullet6")}</li>
              <li>{t("service1Bullet7")}</li>
            </ul>
          </span>
          {t("service1Info")}
        </div>
      </InfoModal>
      <InfoModal
        open={modal2}
        onClose={(value) => setModal2(value)}
        title={t("service1Title2")}
      >
        <p className="box-border mt-0 mb-8 text-gray-500 md:mb-0">
          {t("service1Content2")}
        </p>
      </InfoModal>
      <InfoModal
        open={modal3}
        onClose={(value) => setModal3(value)}
        title={t("service1Title3")}
      >
        <p className="box-border my-0 text-slate-600">
          {t("service1Content3")}
        </p>
      </InfoModal>
      <div className="relative shadow-lg">
        <div className="absolute inset-0 bg-center bg-cover bg-hero-pattern"></div>
        <section className="w-full px-8 text-gray-700 bg-transparent select-none">
          <div className="container flex flex-wrap items-center justify-between py-5 mx-auto bg-transparent max-w-7xl">
            <div className="relative flex bg-transparent">
              <a
                href="#_"
                className="flex flex-col items-start justify-center font-medium text-sky-900 lg:w-auto md:mb-0"
              >
                <span className="mx-auto text-3xl font-black leading-none select-none">
                  FIN<span className="text-white">TAX</span>
                </span>
                <span className="text-xl font-medium">finants</span>
              </a>
              <nav className="flex flex-wrap items-center pl-4 mb-0 ml-4 text-lg bg-transparent border-l border-gray-200">
                <a
                  href="#teenused"
                  className="mr-4 font-medium leading-6 text-white border-white hover:border hover:border-t-0 hover:border-l-0 hover:border-r-0"
                >
                  {t("navLink1")}
                </a>
                <a
                  href="#paketid"
                  className="mr-4 font-medium leading-6 text-white border-white hover:border hover:border-t-0 hover:border-l-0 hover:border-r-0"
                >
                  {t("navLink2")}
                </a>
                <a
                  href="#kontakt"
                  className="mr-4 font-medium leading-6 text-white border-white hover:border hover:border-t-0 hover:border-l-0 hover:border-r-0"
                >
                  {t("navLink3")}
                </a>
              </nav>
            </div>
            <div className="z-10 flex items-center space-x-2">
              <Language color="white" />
              <button
                className={`text-white rounded-md px-2 py-0.5 ${
                  i18n.language === "ru"
                    ? "bg-blue-900"
                    : "bg-white text-blue-900"
                }`}
                onClick={() => i18n.changeLanguage("ru")}
              >
                RU
              </button>
              <button
                className={`text-white rounded-md px-2 py-0.5 ${
                  i18n.language === "ee"
                    ? "bg-blue-900"
                    : "bg-white text-blue-900"
                }`}
                onClick={() => i18n.changeLanguage("ee")}
              >
                EE
              </button>
            </div>
          </div>
        </section>
        <section className="py-16 bg-transparent select-none">
          <div className="flex flex-col max-w-6xl px-10 mx-auto bg-transparent lg:flex-row">
            <div className="flex flex-col items-start justify-center w-full mb-12 bg-transparent lg:w-2/3 lg:mb-0">
              <div className="relative">
                {/* <p className='mb-2 text-base font-bold text-black uppercase'>
                  Raamatupidamisteenus
                </p> */}
                <h1 className="text-4xl font-extrabold tracking-tight text-blue-900">
                  <span className="block xl:inline">{t("heroHeader")}</span>
                  <span className="block pl-1 text-white xl:inline">
                    {t("heroSubHeader")}
                  </span>
                </h1>
              </div>
              <p className="z-10 p-2 my-8 text-lg font-medium text-white rounded-md md:text-xl bg-blue-900/40">
                {t("heroText")}
              </p>
              {/* <div className='relative flex items-center pt-4 space-x-3 bg-transparent'>
                <a href='#kontakt' className='relative text-2xl group'>
                  <span className='relative z-10 px-5 py-2 font-bold leading-tight text-black bg-white border-4 border-gray-900 rounded-lg group-hover:bg-sky-900 group-hover:text-white'>
                    Küsi pakkumist
                  </span>
                  <span className='absolute top-0 right-0 w-full h-14 -mt-2.5 -mr-0.5 bg-black rounded-lg'></span>
                </a>
              </div> */}
            </div>
          </div>
        </section>
      </div>

      <section
        id="teenused"
        className="box-border pt-12 pb-8 leading-7 text-gray-900 bg-white select-none md:pb-12 border-slate-100"
      >
        <div className="flex flex-col items-center leading-7 text-center text-gray-900 border-0 border-gray-200">
          <h2 className="box-border mb-10 text-3xl font-semibold leading-tight tracking-tight text-black border-solid md:text-5xl">
            {t("servicesTitle")}
          </h2>
        </div>
        <div className="box-border relative w-full px-12 mx-auto text-gray-900 max-w-7xl">
          <div className="box-border flex flex-wrap gap-6 mt-0 -mx-5 md:gap-0">
            {/* <!-- Feature 1 --> */}
            <div className="box-border relative flex-none w-full max-w-full px-5 mt-0 md:w-1/3 md:flex-none">
              <div className="mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-14 h-14"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                  />
                </svg>
              </div>
              <h3 className="box-border mt-0 mb-2 text-xl tracking-normal">
                {t("service1Title1")}
              </h3>
              <div
                onClick={() => setModal1(true)}
                className="underline hover:cursor-pointer text-sky-900"
              >
                {t("readMore")}
              </div>
              <div className="relative flex items-center pt-4 space-x-3">
                <a href="#paketid" className="relative text-[14px] group">
                  <span className="relative z-10 px-5 py-2 font-bold leading-tight text-white bg-[#98D502] border-4 border-gray-900 rounded-lg group-hover:bg-[#80CE00] group-hover:text-white">
                    {t("service1Cta")}
                  </span>
                  {/* <span className='absolute top-0 right-0 w-full h-12 -mt-2 -mr-0.5 bg-black rounded-lg'></span> */}
                </a>
              </div>
            </div>
            {/* <!-- Feature 2 --> */}
            <div className="box-border relative flex-none w-full max-w-full px-5 mt-0 md:w-1/3 md:flex-none">
              <div className="mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-14 h-14"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
              <h3 className="box-border mt-0 mb-2 text-xl tracking-normal">
                {t("service1Title2")}
              </h3>
              <div
                onClick={() => setModal2(true)}
                className="underline hover:cursor-pointer text-sky-900"
              >
                {t("readMore")}
              </div>
              <div className="relative flex items-center pt-4 space-x-3">
                <a href="#kontakt" className="relative text-[14px] group">
                  <span className="relative z-10 px-5 py-2 font-bold leading-tight text-white bg-[#98D502] border-4 border-gray-900 rounded-lg group-hover:bg-[#80CE00] group-hover:text-white">
                    {t("service2Cta")}
                  </span>
                  {/* <span className='absolute top-0 right-0 w-full h-12 -mt-2 -mr-0.5 bg-black rounded-lg'></span> */}
                </a>
              </div>
            </div>
            {/* <!-- Feature 3 --> */}
            <div className="box-border relative flex-none w-full max-w-full px-5 mt-0 md:w-1/3 md:flex-none">
              <div className="mb-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-14 h-14"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
              <h3 className="box-border mt-0 mb-2 text-xl font-normal tracking-normal">
                {t("service1Title3")}
              </h3>
              <div
                onClick={() => setModal3(true)}
                className="underline hover:cursor-pointer text-sky-900"
              >
                {t("readMore")}
              </div>
              <div className="relative flex items-center pt-4 mt-2 mb-10 space-x-3">
                <a href="#kontakt" className="relative text-[14px] group">
                  <span className="relative z-10 px-5 py-2 font-bold leading-tight text-white bg-[#98D502] border-4 border-gray-900 rounded-lg group-hover:bg-[#80CE00] group-hover:text-white">
                    {t("service3Cta")}
                  </span>
                  {/* <span className='absolute top-0 right-0 w-full h-12 -mt-2 -mr-0.5 bg-black rounded-lg'></span> */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container flex justify-center mx-auto">
        <img className="w-80" src={illustration} alt="Illustration" />
      </section>
      {/* // <!-- Section 4 --> */}
      <section
        id="paketid"
        className="py-8 leading-7 text-gray-900 bg-white select-none sm:py-12 md:py-16 lg:py-24"
      >
        <div className="box-border px-0 mx-auto border-solid sm:px-6 max-w-7xl">
          <div className="flex flex-col items-center leading-7 text-center text-gray-900 border-0 border-gray-200">
            <h2 className="box-border m-0 text-3xl font-semibold leading-tight tracking-tight text-[#80CE00] border-solid md:text-5xl">
              {t("servicePlansTitle")}
            </h2>
            <p className="box-border px-2 mt-2 text-xl text-gray-900 border-solid">
              {t("servicePlansSubTitle")}
            </p>
          </div>
          <div className="grid grid-cols-1 gap-4 mt-4 leading-7 text-gray-900 border-0 border-gray-200 sm:mt-6 sm:gap-6 md:mt-8 md:gap-0 lg:grid-cols-3">
            {/* <!-- Price 1 --> */}
            <div className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-mr-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
              <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-center text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                {t("planTitle1")}
              </h3>
              <div className="flex items-end mt-6 leading-7 text-gray-900 border-0 border-gray-200">
                <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                  50€
                </p>
                <p
                  className="box-border m-0 border-solid"
                  // style='border-image: initial;'
                >
                  / {t("paymentPeriod")}
                </p>
              </div>
              <p className="mt-6 mb-5 text-sm leading-normal text-left text-gray-900 border-0 border-gray-200">
                {t("planSubTitle")}
              </p>
              <ul className="flex-1 p-0 mt-4 ml-0 leading-7 text-gray-900 border-0 border-gray-200">
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan1Bullet1")}
                </li>
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan1Bullet2")}
                </li>
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan1Bullet3")}
                </li>
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan1Bullet4")}
                </li>
              </ul>
            </div>
            {/* <!-- Price 2 --> */}
            <div className="relative z-20 flex flex-col items-center max-w-md p-4 mx-auto my-0 bg-white border-4 border-blue-900 border-solid rounded-lg sm:p-6 md:px-8 md:py-16">
              <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                {t("planTitle2")}
              </h3>
              <div className="flex items-end mt-6 leading-7 text-gray-900 border-0 border-gray-200">
                <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                  €120
                </p>
                <p
                  className="box-border m-0 border-solid"
                  // style='border-image: initial;'
                >
                  / {t("paymentPeriod")}
                </p>
              </div>
              <p className="mt-6 mb-5 text-sm leading-normal text-left text-gray-900 border-0 border-gray-200">
                {t("planSubTitle")}.
              </p>
              <ul className="flex-1 p-0 mt-4 leading-7 text-gray-900 border-0 border-gray-200">
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan2Bullet1")}
                </li>
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan2Bullet2")}
                </li>
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan2Bullet3")}
                </li>
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan2Bullet4")}
                </li>
              </ul>
            </div>
            {/* <!-- Price 3 --> */}
            <div className="relative z-10 flex flex-col items-center max-w-md p-4 mx-auto my-0 border border-solid rounded-lg lg:-ml-3 sm:my-0 sm:p-6 md:my-8 md:p-8">
              <h3 className="m-0 text-2xl font-semibold leading-tight tracking-tight text-black border-0 border-gray-200 sm:text-3xl md:text-4xl">
                {t("planTitle3")}
              </h3>
              <div className="flex items-end mt-6 leading-7 text-gray-900 border-0 border-gray-200">
                <p className="box-border m-0 text-6xl font-semibold leading-none border-solid">
                  €250
                </p>
                <p
                  className="box-border m-0 border-solid"
                  // style='border-image: initial;'
                >
                  / {t("paymentPeriod")}
                </p>
              </div>
              <p className="mt-6 mb-5 text-sm leading-normal text-left text-gray-900 border-0 border-gray-200">
                {t("planSubTitle")}
              </p>
              <ul className="flex-1 p-0 mt-4 leading-7 text-gray-900 border-0 border-gray-200">
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan3Bullet1")}
                </li>
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan3Bullet2")}
                </li>
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan3Bullet3")}
                </li>
                <li className="inline-flex items-center block w-full mb-2 ml-5 font-semibold text-left border-solid">
                  <svg
                    className="w-5 h-5 mr-2 font-semibold leading-7 text-blue-900 sm:h-5 sm:w-5 md:h-6 md:w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  {t("plan3Bullet4")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container flex justify-center mx-auto my-10">
          <div className="relative flex items-center pt-4 space-x-3">
            <a href="#kontakt" className="relative text-2xl group">
              <span className="relative z-10 px-5 py-2 font-bold leading-tight text-white bg-[#98D502] border-4 border-gray-900 rounded-lg group-hover:bg-[#80CE00] group-hover:text-white">
                {t("ServicePlansCta")}
              </span>
              <span className="absolute top-0 right-0 w-full h-14 -mt-2.5 -mr-0.5 bg-black rounded-lg"></span>
            </a>
          </div>
        </div>
      </section>

      {/* // <!-- Section 5 --> */}
      <div className="py-10 bg-white select-none md:py-16">
        <div className="px-10 mx-auto max-w-7xl md:px-16">
          <div id="kontakt" className="max-w-3xl mx-auto mb-10 md:mb-16">
            <p className="text-xs font-bold text-blue-900 uppercase">
              {t("contactLabel")}
            </p>
            <h2 className="mt-1 text-2xl font-bold text-left text-gray-800 lg:text-3xl md:mt-2">
              {t("contactTitle")}
            </h2>
            <p className="max-w-screen-md mx-auto mt-4 text-left text-gray-500 md:text-lg md:mt-6">
              {t("contactSubTitle")}
            </p>
          </div>
          <div className="grid max-w-3xl gap-4 mx-auto sm:grid-cols-2">
            <div>
              <label
                htmlFor="first-name"
                className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                {t("formFirstName")} *
              </label>
              <input
                onChange={(e) => setFName(e.target.value)}
                name="first-name"
                className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-orange-300"
              />
            </div>

            <div>
              <label
                htmlFor="last-name"
                className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                {t("formLastName")} *
              </label>
              <input
                onChange={(e) => setLname(e.target.value)}
                name="last-name"
                className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-orange-300"
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                {t("formIndustry")}
              </label>
              <input
                onChange={(e) => setIndustry(e.target.value)}
                name="company"
                className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-orange-300"
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                {t("formIemail")} *
              </label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-orange-300"
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="subject"
                className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                {t("formDocCount")}
              </label>
              <input
                onChange={(e) => setDocAmount(e.target.value)}
                name="subject"
                className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-orange-300"
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="subject"
                className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                {t("formHeadCount")}
              </label>
              <input
                onChange={(e) => setEmplAmount(e.target.value)}
                name="subject"
                className="w-full px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-orange-300"
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="inline-block mb-2 text-sm font-medium text-gray-500 sm:text-base"
              >
                {t("formMessage")} *
              </label>
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                name="message"
                className="w-full h-64 px-3 py-2 text-gray-800 transition duration-100 border rounded-md outline-none bg-gray-50 focus:ring ring-orange-300"
              ></textarea>
            </div>

            <div className="flex items-center justify-between sm:col-span-2">
              <button
                onClick={() => handleMessage()}
                className="inline-block px-8 py-3 text-sm font-semibold text-center text-white transition duration-100 bg-[#98D502] rounded-md outline-none hover:bg-[#80CE00] active:bg-[#80CE00] ring-[#80CE00] md:text-base"
              >
                {t("formCta")}
              </button>
            </div>
          </div>
          <p className="max-w-3xl mx-auto mt-5 text-xs text-red-400">
            {formError && formError}
          </p>
          <p className="max-w-3xl mx-auto mt-5 text-xs text-green-400">
            {success && success}
          </p>
        </div>
      </div>

      {/* <!-- Section 1 --> */}
      <section className="text-gray-700 bg-white body-font">
        <div className="container flex flex-col items-center px-8 py-8 mx-auto max-w-7xl sm:flex-row">
          <a
            href="#_"
            className="flex flex-col items-start mb-5 font-medium text-sky-900 lg:w-auto md:mb-0"
          >
            <span className="mx-auto text-xl font-black leading-none select-none">
              FIN<span className="text-blue-900">TAX</span>
            </span>
            <span>finants</span>
          </a>
          <p className="mt-4 text-gray-500 text-md sm:ml-4 sm:pl-4 sm:border-l sm:border-gray-200 sm:mt-0">
            © 2022 Fintax finants OÜ (registrikood 16486200)
          </p>
          <p className="mt-4 text-gray-500 text-md sm:ml-4 sm:pl-4 sm:border-l sm:border-gray-200 sm:mt-0">
            mob.tel.58183830
          </p>
          <span className="inline-flex items-center justify-center mt-4 space-x-5 sm:ml-auto sm:mt-0 sm:justify-start">
            <a
              href="https://msng.link/o/?37258183830=vi"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Helista Viber-iga</span>
              <img className="w-6" src={viberLogo} alt="Viber Logo" />
            </a>

            <a
              href="https://wa.me/+37258183830"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Helista WhatsApp-iga</span>
              <BrandWhatsapp size={27} strokeWidth={2} color={"#25D366"} />
            </a>
          </span>
        </div>
      </section>
      <CreatedBy />
    </div>
  );
}

export default App;
