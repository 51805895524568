import React from 'react';

import { Modal } from '@mantine/core';

function InfoModal({open, onClose, children, title}) {
  const handleModal = () => {
    onClose(!open)
  }
  return (
    <Modal opened={open} centered onClose={handleModal} title={title}>
      {children}
    </Modal>
  );
}

export default InfoModal;